// @flow

import React, { PureComponent } from "react";
import styled from "styled-components";

const SVG = styled.svg`
  display: block;
`;

export default class Bubble1 extends PureComponent<*> {
  render() {
    return (
      <SVG width="55" height="55" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path
            d="M11.2153 16.4093C23.5327 9.42813 36.285 9.62381 49.4723
          16.9963C50.1337 17.0966 49.6948 28.4594 42.8211 38.6145C42.1023 40.6214 41.1905 42.6407 40.0441
          44.619C39.9056 44.8579 39.5997 44.9394 39.3608 44.8009C39.1218 44.6625 39.0404 44.3565 39.1788 44.1176C39.6255
          43.3468 40.0357 42.5692 40.4121 41.7882C37.7948 44.8663 34.481 47.6928 30.3034 49.8764C17.9444 41.7444 11.5817
          30.5887 11.2153 16.4093ZM9.20453 22.8187C9.41436 22.6392 9.43894 22.3236 9.25943 22.1138C9.07992 21.9039 8.76429
          21.8793 8.55446 22.0589C7.71188 22.7797 6.88434 23.5444 6.07182 24.3529L5.86152 24.5621L5.94441 24.847C6.26458
          25.9473 6.6169 27.0193 7.00143 28.0629C7.09691 28.322 7.38436 28.4547 7.64347 28.3592C7.90258 28.2637 8.03523
          27.9762 7.93975 27.7171C7.59676 26.7863 7.27966 25.8315 6.9885 24.8528C7.71574 24.1384 8.45443 23.4604 9.20453
          22.8187ZM10.697 33.7255C10.5618 33.4847 10.2571 33.399 10.0163 33.5342C9.77544 33.6693 9.68977 33.9741 9.82491
          34.2149C10.9477 36.2156 12.2212 38.0817 13.6457 39.8128C13.8212 40.026 14.1363 40.0567 14.3495 39.8812C14.5627
          39.7057 14.5933 39.3906 14.4179 39.1774C13.0319 37.493 11.7915 35.6759 10.697 33.7255ZM36.0706 50.1407C36.2533
          49.9337 36.2336 49.6177 36.0266 49.435C35.8196 49.2523 35.5036 49.272 35.3209 49.479C34.6685 50.2182 33.971 50.9422
          33.2256 51.6474C32.2568 51.3184 31.3128 50.9674 30.3937 50.5944C30.1378 50.4906 29.8462 50.6139 29.7424 50.8698C29.6386
          51.1256 29.7619 51.4172 30.0177 51.5211C31.0461 51.9383 32.104 52.3283 33.1913 52.691L33.4728 52.7848L33.69 52.5829C34.5416
          51.7911 35.3339 50.9754 36.0706 50.1407ZM19.0566 43.8696C18.8455 43.6915 18.5301 43.7183 18.352 43.9293C18.174 44.1404 18.2007
          44.4559 18.4118 44.6339C20.1121 46.0682 21.957 47.3701 23.9458 48.5397C24.1838 48.6796 24.4902 48.6002 24.6302 48.3621C24.7702
          48.1241 24.6907 47.8177 24.4527 47.6777C22.5106 46.5356 20.7121 45.2661 19.0566 43.8696Z"
            fill="#F0F3F8"
          />
          <path
            d="M37.228 36.1565V32.9645H33.796V19.4285H26.5V22.6205H30.124V32.9645H26.5V36.1565H37.228Z"
            fill="#1493FF"
            fillRule="nonzero"
          />
        </g>
      </SVG>
    );
  }
}
