import {
  ModalPayloadType,
  AccountWalletOpenModalAction,
  AccountWalletCloseModalAction
} from "./types";

export const openAccountWalletModal = (
  payload: ModalPayloadType
): AccountWalletOpenModalAction => ({
  type: "OPEN_AW_MODAL",
  payload
});

export const closeAccountWalletModal = (): AccountWalletCloseModalAction => ({
  type: "CLOSE_AW_MODAL"
});
