// @flow

import React, { PureComponent } from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { connect } from "react-redux";
import mediator from "@tvg/mediator";
import { get, attempt } from "lodash";
import TVGConf from "@tvg/conf";
import type { Props as BaseProps, State as BaseState } from "@tvg/types/Login";
import { openExternalApp } from "@tvg/utils/mediatorUtils";
import interceptRequests from "@tvg/perimeterx/src";
import isMobile from "@tvg/utils/mobileUtils";
import { onTriggerGeolocation } from "@tvg/sh-utils/mobileUtils";
import {
  getGeoComplyRetryStatus,
  getGeolocationMessageShown,
  getGeolocationStatus
} from "@tvg/sh-geolocation/src/redux/selectors";
import {
  geolocationStatusClear,
  setGeolocationStatus,
  setGeolocationError
} from "@tvg/sh-geolocation/src/redux/actions";
import { getRejectedErrorTitle } from "@tvg/sh-geolocation/src/utils/getRejectedErrorTitle";
import { Toast } from "@tvg/design-system/web";
import { Modal } from "@tvg/design-system";
import pluralize from "@fdr/utils/generalUtils";

import requestGeolocation, {
  setShowStateSelector,
  setGeoLocationState
} from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { geoComplySubscriptions } from "@tvg/sh-geolocation/src/utils/subscriptions";
import { StartGeoComply } from "@tvg/sh-geolocation/src/components/startGeoComply";
import AccountWalletModal from "@tvg/sh-lib-account-wallet/src/Modals/AccountWalletModal";
import { getAwFlowsConfig } from "@tvg/sh-lib-account-wallet/src/config";
import StateSelector from "@tvg/sh-lib-account-wallet/src/components/StateSelector";
import {
  getSportsbookRegionCookie,
  getAuthTokenCookie,
  isAccountCompliantSelector,
  getUserSessionData,
  getUserSessionStartDate
} from "@tvg/sh-utils/sessionUtils";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import {
  getPromoOnboardingPollerTime,
  getPromosOnboardingToggle
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { handleAWEvents } from "@tvg/sh-utils/awUtils";
import {
  onTriggerJoin,
  onTriggerLogin,
  onRefreshSession,
  onOpenComplianceModal
} from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import {
  clearSession,
  logout
} from "@tvg/sh-lib-account-wallet/src/session/logout";
import ErrorPage from "@tvg/sh-lib-account-wallet/src/components/PageError";
import { MapImageEnum } from "@tvg/sh-lib-account-wallet/src/components/PageError/types";
import Poller from "@tvg/poller";
import { isTvg5 } from "@tvg/utils/generalUtils";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import initializeBraze from "@tvg/braze/src/userBraze";
import { setUserJurisdiction } from "@fdr/shared-actions/UserActions";
import { sessionStartUpdate } from "@tvg/shared-actions/UserActions";
import { getUserJurisdiction } from "@tvg/sh-utils/userJurisdictionUtils";
import {
  getAccountNumber,
  getBalance,
  getEmail,
  getResidenceState
} from "@urp/store-selectors";
import type { UserJurisdiction } from "@tvg/api/aw/types";
import type { NullaryFn } from "@tvg/types/Functional";
import { LoadingSplash } from "@urp/location-splash";
import {
  closeLocationSplash,
  openLocationSplash
} from "@tvg/location-splash/actions";
import { MediatorEventType as LoginModalAmplitudeEvents } from "@tvg/amplitude/modules/loginModal/types";
import fetchUserFavorites, {
  updateFavoriteTrack,
  updateUserFavoriteTracks
} from "./utils/userFavorites";
import getUserPreferences from "./utils/userPreferences";
import { getIsContentCardsOpen, getIsOpenErrorModal } from "./selectors/modal";
import {
  controllerDidMountCommon,
  manageEquibaseId,
  promosSubscriber
} from "./utils/controllerLogic";
import { clearOptedInPromos } from "./services/optedInPromos";
import userPromoOnboarding from "./utils/userPromoOnboarding";
import { getUserData, setIsLoginErrorPage } from "./actions/login";
import { openErrorModal } from "./actions/modal";
import { initialState } from "./reducers/userDataReducer";

const PromosPoller = new Poller();
const RefreshSessionPoller = new Poller();

type flowInProcessType = { type: string, payload?: { [string]: * } };

type State = {
  flowInProcess: flowInProcessType | null
} & BaseState;
type Props = {
  locationSplashMessages: { [string]: any },
  isBetSlipOpen: boolean
} & BaseProps;

export class LoginControllerAW extends PureComponent<Props, State> {
  awFlowsSDK;

  loginCallback = null;

  siftInitialised = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      subscription: null,
      promoEligible: null,
      openLoginRetryCounter: 0,
      flowInProcess: null
    };
  }

  handleLogin(data: { payload: { callback: NullaryFn<void> } }) {
    const callback = get(data, "payload.callback");

    const hasCallback = typeof callback === "function";
    const sbkRegionCookie = getSportsbookRegionCookie();

    if (!sbkRegionCookie) {
      this.props.dispatch(setShowStateSelector(true));
    } else {
      onTriggerLogin(this.awFlowsSDK);
    }

    // When in XSell and FDR isn't yet aware of the user session
    // wait for launchInRegion dispatch from SBK to provide context.
    // getSportsbookRegionCookie() === launchInRegion dispatched
    // getAuthTokenCookie() === user has session started in SBK
    const userIsLogged = this.props.user.isLogged;
    const hasPossibleWebSession =
      !isMobile(TVGConf().product) && getAuthTokenCookie();
    if (hasPossibleWebSession) {
      if (userIsLogged) {
        if (this.state.openLoginRetryCounter > 0) {
          this.setState({
            openLoginRetryCounter: 0
          });
        }

        if (getAuthTokenCookie()) {
          if (!this.props.user.sessionStartAt) {
            this.props.dispatch(
              sessionStartUpdate(getUserSessionStartDate(this.props.authToken))
            );
          }
          if (hasCallback) {
            callback();
          }
          return;
        }
      } else if (this.state.openLoginRetryCounter < 3) {
        this.setState((prevState) => ({
          openLoginRetryCounter: prevState.openLoginRetryCounter + 1
        }));
        // $FlowFixMe
        if (this.timeoutLogin) {
          // $FlowFixMe
          clearTimeout(this.timeoutLogin);
        }
        // $FlowFixMe
        this.timeoutLogin = setTimeout(() => mediator.base.dispatch(data), 500);
        return;
      } else if (
        hasPossibleWebSession &&
        this.state.openLoginRetryCounter === 3
      ) {
        mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });
        return;
      }
    }
    if (hasCallback) {
      this.loginCallback = callback;
    }
  }

  handleGeolocation(
    onGetGeolocation: NullaryFn<void>,
    flowInProcess: flowInProcessType
  ) {
    if (this.props.geolocationStatus === "PERMISSION_DENIED") {
      this.props.dispatch(openErrorModal());
      return;
    }

    if (this.state.flowInProcess || getSportsbookRegionCookie()) {
      onGetGeolocation();
      return;
    }

    this.props.dispatch(openLocationSplash());
    this.setState({ flowInProcess });

    requestGeolocation({
      dispatch: this.props.dispatch,
      allowedStates: this.props.allowedStates ?? [],
      isUserLogged: this.props.user.isLogged
    })
      .then(() => {
        onGetGeolocation();
      })
      .catch((error) => {
        if (error.message === "PERMISSION_DENIED") {
          this.props.dispatch(openErrorModal());

          this.props.dispatch(setGeolocationStatus(error.message));
          this.props.dispatch(setGeolocationError(error.message, [], true));
        } else {
          this.props.dispatch(setShowStateSelector(true));
        }
      })
      .finally(() => {
        this.props.dispatch(closeLocationSplash());
      });
  }

  componentDidMount(): void {
    if (typeof window !== "undefined" && !this.awFlowsSDK) {
      // $FlowFixMe
      this.awFlowsSDK =
        // $FlowFixMe
        require("@fanduel/aw-flows-react").AWFlows;
      this.awFlowsSDK.setConfig(getAwFlowsConfig(TVGConf().environment));
    }

    const sportsbookRegionCookie = getSportsbookRegionCookie();
    // $FlowFixMe
    if (!this.props.geolocation?.state && sportsbookRegionCookie) {
      this.props.dispatch(setGeoLocationState(sportsbookRegionCookie));
    }

    handleAWEvents(
      this.awFlowsSDK,
      this.props.dispatch,
      this.cleanLoginCallback,
      () => this.props
    );

    geoComplySubscriptions(this.props.dispatch, {
      twoFactorAuthStates: this.props.twoFactorAuthStates,
      wageringStates: this.props.allowedStates
    });
    mediator.base.subscribe("GET_USER_JURISDICTION", () => {
      const { dispatch } = this.props;
      getUserJurisdiction().then((jurisdiction: UserJurisdiction) => {
        dispatch(setUserJurisdiction(jurisdiction));
      });
    });

    mediator.base.subscribe(
      "UPDATE_SESSION_FAVORITE_TRACKS",
      updateFavoriteTrack(this.props.dispatch)
    );

    mediatorClassic.subscribe(
      "NEW_FAVORITE_TRACKS",
      updateUserFavoriteTracks(this.props.dispatch)
    );

    mediator.base.subscribe("OPEN_LOGIN", ({ payload }) => {
      mediator.base.dispatch({
        type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
        payload
      });
    });

    mediator.base.subscribe("TVG_LOGIN:DO_LOGOUT", () => {
      logout();
      clearSession({
        history: this.props.history,
        dispatch: this.props.dispatch,
        geolocationError:
          this.props.geolocationError[this.props.geolocationStatus],
        user: initialState
      });
      manageEquibaseId(false);
      mediator.base.dispatch({ type: "FETCH_QUICKLINKS" });
    });

    mediator.base.subscribe("OPEN_SIGNUP", () => {
      this.handleGeolocation(
        () => {
          if (!getSportsbookRegionCookie()) {
            this.props.dispatch(setShowStateSelector(true));
          } else {
            onTriggerJoin(this.awFlowsSDK);
          }
        },
        { type: "OPEN_SIGNUP" }
      );
    });

    mediatorClassic.subscribe("TVG_LOGIN:OPEN_LOGIN_MODAL", (data) => {
      if (!this.props.isAccountCompliant && this.props.user.isLogged) {
        onOpenComplianceModal();
      } else {
        mediator.base.dispatch({
          type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
          payload: data
        });
      }
    });

    mediator.base.subscribe("TVG_LOGIN:USER_SESSION_UPDATE", (data) => {
      if (TVGConf().device === "desktop" || TVGConf().product === "tvg4") {
        // Let's TVG4 know that the user is logged
        mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
          ...data.payload
        });
      }

      this.props.dispatch(getUserData(data.payload));
    });

    mediator.base.subscribe("TVG_LOGIN:OPEN_LOGIN_MODAL", (data) => {
      this.handleGeolocation(() => this.handleLogin(data), {
        type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
        payload: data.payload
      });
    });

    mediator.base.subscribe("OPEN_LOGIN_SUCCESS", (data) => {
      const { user, jurisdiction, isVerified } = get(data, "payload");
      const isAccountCompliant = isVerified && jurisdiction?.accepted;

      mediator.base.dispatch({
        type: "FETCH_QUICKLINKS"
      });

      if (isAccountCompliant) {
        mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
          user: {
            ...user,
            status: "Success"
          },
          logged: true,
          hasRequested: true
        });

        mediator.base.dispatch({
          type: "TVG_LOGIN:LOGIN_SUCCESS",
          userData: user
        });

        this.props.dispatch(setIsLoginErrorPage(false));
      }

      if (typeof this.loginCallback === "function") {
        if (isAccountCompliant) {
          const error = null;
          const success = {
            status: "success",
            data: {
              userDetails: user
            }
          };
          this.loginCallback(error, success);
        }
      }

      this.setState({ flowInProcess: null });

      // Reset it again
      this.cleanLoginCallback();
    });

    mediator.base.subscribe(
      "TVG_LOGIN:GET_USER_PROMOS",
      this.handlePromosSubscribe
    );

    mediator.base.subscribe("TVG_LOGIN:CLEAR_USER_PROMOS", () => {
      clearOptedInPromos(this.props.dispatch);
    });

    // get 403 requests from perimeterx
    interceptRequests();

    // util made with common logic between AW and TVG Flows
    controllerDidMountCommon({
      dispatch: this.props.dispatch
    });
  }

  getBrazeUserId = (authToken: string) => {
    return getUserSessionData(authToken).fdUserId.toString();
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.user.isLogged &&
      this.props.user.accountNumber &&
      this.props.user.accountNumber !== "" &&
      !this.props.promos.gotPromos &&
      !this.props.promos.loadingPromos
    ) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });

      if (!PromosPoller.isRunning() && isTvg5()) {
        PromosPoller.start(
          () =>
            mediator.base.dispatch({
              type: "TVG_LOGIN:GET_USER_PROMOS"
            }),
          60000
        );
      }
    }

    if (this.props.authToken && !this.siftInitialised)
      this.pushSiftWindowVariables();

    if (
      (!prevProps.user.isLogged && this.props.user.isLogged) ||
      (this.props.user.isLogged &&
        prevProps.user.accountNumber !== this.props.user.accountNumber) ||
      prevProps.geolocation !== this.props.geolocation
    ) {
      if (!this.props.user?.sessionStartAt) {
        this.props.dispatch(
          sessionStartUpdate(getUserSessionStartDate(this.props.authToken))
        );
      }
      getUserPreferences(
        this.props.user.accountNumber,
        this.props.dispatch,
        this.props.overwritePreferences
      );
      if (this.props.isAccountCompliant && this.props.user.accountNumber) {
        fetchUserFavorites(this.props.user.accountNumber, this.props.dispatch);
      }
      attempt(() => window.localStorage.setItem("userLoginOnce", true));
      manageEquibaseId(true);
    }

    if (!this.props.user.isLogged && this.props.promos.gotPromos) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:CLEAR_USER_PROMOS"
      });
    }

    const setState = this.setState.bind(this);

    initializeBraze(
      prevProps,
      this.props,
      this.state.subscription,
      setState,
      this.props.dispatch,
      this.getBrazeUserId(this.props.authToken || ""),
      this.getBrazeUserId(this.props.authToken || ""),
      this.props.brazeRefreshExtraPollerTimer
    );

    userPromoOnboarding(this.props, prevProps, this.state, prevState, setState);

    if (!this.props.authToken && prevProps.authToken !== this.props.authToken) {
      mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });
    }

    if (
      prevProps.user.isLogged !== this.props.user.isLogged ||
      prevProps.user.accountNumber !== this.props.user.accountNumber
    ) {
      this.refreshSession(this.props.user.isLogged);
    }
  }

  componentWillUnmount() {
    mediator.base.unsubscribe(
      "TVG_LOGIN:GET_USER_PROMOS",
      this.handlePromosSubscribe
    );
  }

  handlePromosSubscribe = () => {
    promosSubscriber({
      isLogged: this.props.user.isLogged,
      accountNumber: this.props.user.accountNumber,
      loadingPromos: this.props.promos.loadingPromos,
      dispatch: this.props.dispatch
    });
  };

  cleanLoginCallback = () => {
    this.loginCallback = null;
  };

  refreshSession(isLogged: boolean) {
    if (isLogged) {
      RefreshSessionPoller.start(
        () => onRefreshSession(this.awFlowsSDK),
        this.props.refreshSessionPollerTimer
      );
    } else {
      RefreshSessionPoller.stop();
    }
  }

  pushSiftWindowVariables = () => {
    const { authToken } = this.props;
    const tokenUserData = getUserSessionData(authToken);
    if (
      tokenUserData.fdUserId &&
      window._sift[1] !== ["_setUserId", `${tokenUserData.fdUserId}`] &&
      this.props.enableSift &&
      this.props.user.isLogged &&
      !this.siftInitialised
    ) {
      window._sift.push(["_setUserId", `${tokenUserData.fdUserId}`]);
      window._sift.push(["_setSessionId", `${tokenUserData.sessionId || ""}`]);
      window._sift.push(["_trackPageview"]);
      this.siftInitialised = true;
    }
  };

  getGeoComplyToastOffset = () => {
    if (typeof window !== "undefined" && this.props.isBetSlipOpen) {
      const element = document.getElementById("bet-slip-wrapper");

      if (element) {
        const style = getComputedStyle(element);
        const paddingY =
          parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

        return element.clientHeight - paddingY;
      }
    }

    return 0;
  };

  render() {
    const currentPathWithParams =
      this.props.location.pathname + this.props.location.search;

    const isGeolocationPermissionDenied =
      this.props.geolocationStatus === "PERMISSION_DENIED";

    const errorPageSecondaryButton = isGeolocationPermissionDenied && {
      title: "Dismiss",
      onClick: () => {
        this.props.dispatch(openErrorModal());
        mediator.base.dispatch("LOGIN_MODAL_LOCATION_MODAL_CLOSED");
      }
    };

    const geolocationImage =
      this.props.geolocationStatus === "GEOLOCATION_REJECTED" ||
      isGeolocationPermissionDenied
        ? MapImageEnum.ERROR
        : MapImageEnum.PIN;

    return (
      <React.Fragment>
        {this.props.geolocationStatus &&
          this.props.geoLocationToastMsg &&
          this.props.geoLocationToastMsg[this.props.geolocationStatus] && (
            <Toast
              {...this.props.geoLocationToastMsg[this.props.geolocationStatus]}
              noAnimation={this.props.geolocationMessageShown}
              callback={() => this.props.dispatch(geolocationStatusClear())}
              offset={this.getGeoComplyToastOffset()}
            />
          )}
        <LoadingSplash
          isOpen={this.props.isLocationSplashOpen}
          messages={this.props.locationSplashMessages}
        />
        <AccountWalletModal
          BaseComponent={ModalV2}
          modalTopChildren={() => {}}
          isOpen={this.props.accountWallet?.open}
          title={this.props.accountWallet?.title}
          destinationPath={this.props.accountWallet?.path}
          currentPath={currentPathWithParams}
          dispatch={this.props.dispatch}
        />
        <StateSelector
          currentPath={currentPathWithParams}
          // $FlowFixMe
          flowInProcess={this.state.flowInProcess}
          onClose={() => this.setState({ flowInProcess: null })}
        />
        <StartGeoComply
          geoState={get(this.props.geolocation, "state")}
          isLogged={this.props.user.isLogged}
          geoComplyRetryStatus={this.props.geoComplyRetryStatus}
        />
        <Modal
          type="lightbox"
          title={
            this.props.isLoginError
              ? this.props.loginFailedMessage?.title
              : this.props.geolocationBannerMsg[this.props.geolocationStatus]
                  ?.text
          }
          isOpen={this.props.isErrorModalOpen}
          onClose={() => {
            this.props.dispatch(openErrorModal());
            if (this.props.isLoginError) {
              mediator.base.dispatch({
                type: LoginModalAmplitudeEvents.LOGIN_ERROR_MODAL_CLOSED
              });
            }
            if (isGeolocationPermissionDenied) {
              mediator.base.dispatch({
                type: LoginModalAmplitudeEvents.LOCATION_MODAL_CLOSED
              });
            }
          }}
          padding={0}
          hasBorderBottom={false}
          isDarkMode
          onOpen={() => {
            if (isGeolocationPermissionDenied) {
              mediator.base.dispatch({
                type: LoginModalAmplitudeEvents.LOCATION_MODAL_VIEWED
              });
            }
            if (this.props.isLoginError) {
              mediator.base.dispatch({
                type: LoginModalAmplitudeEvents.LOGIN_ERROR_MODAL_VIEWED
              });
            }
          }}
        >
          <ErrorPage
            imageType={
              this.props.isLoginError
                ? MapImageEnum.LOGIN_ERROR
                : geolocationImage
            }
            isGenericError={this.props.geolocationStatus === "LOCATION_FAILURE"}
            title={
              this.props.isLoginError
                ? this.props.loginFailedMessage?.message
                : this.props.geoLocationErrorModalTitle[
                    this.props.geolocationStatus
                  ]
            }
            errorsMessages={getRejectedErrorTitle(
              this.props.geoLocationRejectedErrorModalTitle,
              this.props.geolocationError[this.props.geolocationStatus]
            )}
            errorsTitle={`We've found ${pluralize(
              this.props.geolocationError[this.props.geolocationStatus]?.length,
              "error"
            )}`}
            primaryButton={
              this.props.geolocationErrorRetryable
                ? {
                    title: isGeolocationPermissionDenied
                      ? "Try Again"
                      : "Retry",
                    onClick: () => {
                      this.props.dispatch(openErrorModal());
                      if (isGeolocationPermissionDenied) {
                        mediator.base.dispatch({
                          type: LoginModalAmplitudeEvents.LOCATION_MODAL_CTA_CLICKED,
                          payload: {
                            linkText: "Try Again",
                            linkUrl: location.href
                          }
                        });
                        location.reload();
                        return;
                      }
                      onTriggerGeolocation();
                    }
                  }
                : {
                    title: "Contact Support",
                    onClick: () => {
                      this.props.dispatch(openErrorModal());
                      mediator.base.dispatch({
                        type: LoginModalAmplitudeEvents.LOGIN_ERROR_MODAL_CTA_CLICKED,
                        payload: {
                          linkUrl: this.props.globalFDRSupportLink
                        }
                      });
                      openExternalApp(this.props.globalFDRSupportLink);
                    }
                  }
            }
            secondaryButton={errorPageSecondaryButton}
            description={
              !this.props.isLoginError &&
              get(
                this.props.geoLocationErrorModalTips,
                this.props.geolocationStatus,
                this.props.geoLocationErrorModalTips.LOCATION_FAILURE
              )
            }
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect((store) => ({
  user: {
    firstName: get(store, "userData.user.firstName"),
    lastName: get(store, "userData.user.lastName"),
    isLogged: get(store, "userData.logged"),
    returningUser: get(store, "userData.returningUser", false),
    balance: getBalance(store),
    balancePoller: get(store, "userData.balancePoller"),
    wagerProfile: get(store, "userData.user.profile"),
    accountNumber: getAccountNumber(store),
    userName: get(store, "userData.user.userName"),
    homeState: getResidenceState(store),
    emailAddress: getEmail(store),
    isVerified: get(store, "userData.isVerified"),
    isTermsAccepted: get(store, "userData.jurisdiction.accepted"),
    sessionStartAt: get(store, "userData.sessionStartAt")
  },
  androidGpsAllowedStates: get(
    store,
    "capi.messages.androidGpsAllowedStates",
    ""
  ),
  isLoginError: get(store, "userData.isLoginError", false),
  splashError: get(store, "locationSplash.error", null),
  isLocationSplashOpen: get(store, "locationSplash.isOpen", false),
  isLocationRequired: get(store, "locationSplash.isLocationRequired", false),
  onLocationGet: get(store, "locationSplash.onLocationGet", null),
  brazePoller: get(store, "userData.brazePoller", 60),
  touchId: {
    initEnabled: get(store, "ios.init.touchIdEnabled", false),
    enabled: get(store, "ios.touchId.touchIdEnabled", false),
    accountId: get(store, "ios.touchId.accountId", ""),
    userChangingTouchId: get("store.ios.userChangingTouchId", false),
    token: get(store, "ios.touchId.token", "")
  },
  geolocation: get(store, "geolocation"),
  contentCardsOpen: getIsContentCardsOpen(
    store,
    TVGConf().device === "desktop"
  ),
  loginModal: store.loginModal,
  geopacketUsage: get(
    store,
    "capi.featureToggles.geopacketUsage",
    get(store, "header.features.geopacketUsage", false)
  ),
  locationRequired: get(
    store,
    "capi.messages.statesWithLocationRequired",
    '["ca", "nj", "az"]'
  ),
  promos: {
    gotPromos: get(store, "userData.gotPromos"),
    loadingPromos: get(store, "userData.loadingPromos")
  },
  useAndroidGpsAllowedStates: get(
    store,
    "capi.featureToggles.useAndroidGpsAllowedStates",
    false
  ),
  enableSift: get(store, "capi.featureToggles.enableSift", false),
  isAccountCompliant: isAccountCompliantSelector(store),
  promoOnboardingToggle: getPromosOnboardingToggle(store),
  promoOnboardingPollerTime: getPromoOnboardingPollerTime(store),
  enableAWFlows: get(store, "capi.featureToggles.enableAWFlows", false),
  allowedStates: Object.keys(
    parseJSONCapiMessage(store, "capi.messages.stateSelectorListFdr", {})
  ),
  accountWallet: get(store, "modal.accountWallet"),
  authToken: getAuthTokenCookie(),
  geolocationStatus: getGeolocationStatus(store),
  twoFactorAuthStates: parseJSONCapiMessage(
    store,
    "capi.messages.twoFactorAuthStates",
    []
  ),
  geoComplyRetryStatus: getGeoComplyRetryStatus(store),
  geolocationMessageShown: getGeolocationMessageShown(store),
  geoLocationToastMsg: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationToastMsg",
    {}
  ),
  isErrorModalOpen: getIsOpenErrorModal(store),
  geoLocationRejectedErrorModalTitle: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationRejectedErrorModalTitle",
    {}
  ),
  geoLocationErrorModalTitle: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationErrorModalTitle",
    {}
  ),
  geolocationErrorRetryable: get(store, "geolocation.isErrorRetryable"),
  geolocationBannerMsg: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationBannerMsg",
    {}
  ),
  geoLocationErrorModalTips: parseJSONCapiMessage(
    store,
    "capi.messages.geoLocationErrorModalTips",
    {}
  ),
  globalFDRSupportLink: get(store, "capi.messages.globalFDRSupportLink", ""),
  geolocationError: get(store, "geolocation.geolocationError", {}),
  overwritePreferences: parseJSONCapiMessage(
    store,
    "capi.messages.overwritePreferences",
    {}
  ),
  refreshSessionPollerTimer: get(
    store,
    "capi.messages.refreshSessionPollerTimer",
    3000
  ),
  brazeRefreshExtraPollerTimer: get(
    store,
    "capi.messages.brazeRefreshExtraPollerTimer",
    0
  ),
  locationSplashMessages: parseJSONCapiMessage(
    get(store, "capi.messages"),
    "loginErrorMessages"
  ),
  loginFailedMessage: parseJSONCapiMessage(
    get(store, "capi.messages"),
    "loginFailedMessage"
  ),
  isBetSlipOpen: get(store, "BetTicket.betSlipOpen", false)
}))(LoginControllerAW);
