import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import mediator from "@tvg/mediator";
// @ts-ignore
import AccountMenuLink from "@urp/account-menu/src/components/AccountMenuLink";
import { setShowStateSelector } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { resetStateLocationSplashAction } from "@urp/location-splash/src/actions";
import { breakpoints, useMediaQuery, Icon } from "@tvg/design-system";
import { useColorTokens } from "@tvg/design-system/web/hooks";
// Temporary import from @fdr/static-ui until Logo is updated properly in @tvg/design-system
import { LogoFDR } from "@fdr/static-ui/Logos";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import {
  StateSelectorWrapper,
  Title,
  Description,
  MenuItemsGroup,
  MenuItem,
  ButtonIconContainer,
  Header,
  StateSelectorBackground,
  CloseStateSelector
} from "./styled-components";
import { Props } from "./types";

export const StateSelector = ({
  qaLabel = "state-selector",
  title,
  description,
  states,
  onSelectState,
  to,
  missingStateRedirect
}: Props) => {
  const colors = useColorTokens();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(breakpoints.desktop.min.lg);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const closeModal = () => {
    dispatch(setShowStateSelector(false));
    dispatch(resetStateLocationSplashAction());
    mediator.base.dispatch({
      type: "CLEAR_CALLBACK"
    });
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <StateSelectorBackground isDesktop={isDesktop} onClick={closeModal}>
      <StateSelectorWrapper
        data-qa-label={qaLabel}
        isDesktop={isDesktop}
        onClick={stopPropagation}
      >
        <Header isDesktop={isDesktop}>
          {LogoFDR({ height: 30 })}
          <ButtonIconContainer onClick={closeModal}>
            <Icon
              qaLabel="state-select-close"
              name="close"
              size="m"
              lineColor={colors.content.onDark}
            />
          </ButtonIconContainer>
        </Header>
        <Title data-qa-label={`${qaLabel}-title`}>{title}</Title>
        <Description data-qa-label={`${qaLabel}-description`}>
          {description}
        </Description>
        <MenuItemsGroup data-qa-label={`${qaLabel}-items-group`}>
          {states.map((state) => (
            <MenuItem key={state.abbreviation}>
              <AccountMenuLink
                qaLabel={`${qaLabel}-item-${state.abbreviation}`}
                onClick={() => onSelectState && onSelectState(state)}
                linkText={state.name}
                hideLeftIcon
                to={to}
              />
            </MenuItem>
          ))}
        </MenuItemsGroup>
        <MenuItem
          data-qa-label={`${qaLabel}-item-no-state`}
          onClick={() => {
            closeModal();
            openExternalLink(missingStateRedirect);
          }}
        >
          <CloseStateSelector data-qa-label={`${qaLabel}-item-no-state-text`}>
            I don&apos;t see my state
          </CloseStateSelector>
        </MenuItem>
      </StateSelectorWrapper>
    </StateSelectorBackground>
  );
};

export default StateSelector;
