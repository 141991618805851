// @flow

import React from "react";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import Icon from "@fdr/static-ui/Icons";
import { arrowRight, fanduel, tvg } from "@fdr/static-ui/Icons/icons";
import buildColor from "@fdr/static-ui/ColorPalette";
import {
  SelectionsContainer,
  PlataformPickerTitle,
  PlatformPicker,
  PlatformContainer,
  BrandImageWrapper,
  BrandFooter,
  Brand
} from "./styled-components";

type Props = {
  brand: string,
  handleButton: NullaryFn<void>,
  handleSecondaryButton: NullaryFn<void>,
  chooseMessage: string,
  qaLabel: string
};

const AccountMigrationsSelections = (props: Props) => {
  const { brand, handleButton, handleSecondaryButton, chooseMessage, qaLabel } =
    props;

  const renderPlatform = (
    brandName: string,
    handleClick: NullaryFn<void>,
    isTvg: boolean = false
  ) => (
    <PlatformContainer
      qaLabel={`${qaLabel}-section-platform`}
      onClick={() => handleClick()}
    >
      <BrandImageWrapper data-qa-label={`${qaLabel}-image-brand`} isTvg={isTvg}>
        <Icon
          data-qa-label={`${qaLabel}-icon-brand`}
          icon={isTvg ? tvg : fanduel}
          size={50}
          color={buildColor("blue", "500")}
        />
      </BrandImageWrapper>
      <BrandFooter data-qa-label={`${qaLabel}-section-footer`}>
        <Brand data-qa-label={`${qaLabel}-footer-brand`}>{brandName}</Brand>
        <Icon
          data-qa-label={`${qaLabel}-footer-icon`}
          icon={arrowRight}
          size={16}
          color={buildColor("white", "100")}
        />
      </BrandFooter>
    </PlatformContainer>
  );

  return (
    <SelectionsContainer data-qa-label={`${qaLabel}-section-platform`}>
      <PlataformPickerTitle
        data-qa-label={`${qaLabel}-platform-title`}
        dangerouslySetInnerHTML={{ __html: chooseMessage }}
      />
      <PlatformPicker data-qa-label={`${qaLabel}-platform-picker`}>
        {renderPlatform(brand, handleButton, true)}
        {renderPlatform("FanDuel Racing", handleSecondaryButton)}
      </PlatformPicker>
    </SelectionsContainer>
  );
};

AccountMigrationsSelections.defaultProps = {
  brand: "",
  handleButton: noop,
  handleSecondaryButton: noop,
  chooseMessage: "",
  qaLabel: ""
};

export default AccountMigrationsSelections;
