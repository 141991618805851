// @flow
import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import Icon from "@fdr/static-ui/Icons";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* stylelint-disable */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  /* stylelint-enable */
  background-color: ${({ isAndroidBlocked }) =>
    !isAndroidBlocked ? buildColor("navy", "700") : buildColor("grey", "100")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1002;
`;

export const HeaderWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
`;

export const LogoWrapper = styled.div`
  margin-top: 24px;
  color: ${buildColor("white", "100")};
`;

export const Close = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: ${buildColor("white", "00")};
  outline: none;
`;

export const FooterWrapper = styled.section`
  width: 100%;
  padding: 0 12px;
  margin-bottom: 12px;
`;

export const FanduelIcon = styled(Icon)`
  margin-bottom: 16px;
`;
