import React, { useMemo } from "react";
import type { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
// @TVG_MIGRATION we need to unify this type of utils in a way that we can use in FDR and TVG
// we shouldn't have any import that contains "@fdr" in this package
import { isMobile } from "@tvg/sh-utils/mobileUtils";
import {
  getWrapperAppConfigCookie,
  removeWrapperAppConfigCookie
} from "@tvg/sh-utils/sessionUtils";
import type { TransitionState } from "@tvg/ts-types/Modal";
import { Device } from "@tvg/ts-types/Device";
import { Product } from "@tvg/ts-types/Product";
import type { AccountWalletCloseModalAction } from "../../redux/types";
import { closeAccountWalletModal } from "../../redux/actions";
import AccountWalletWrapper from "../../components/AccountWalletWrapper";
import { isFdrDesk } from "../../utils/mobileUtils";

export interface Props {
  BaseComponent: React.ComponentClass | React.FunctionComponent;
  modalTopChildren: () => React.ComponentClass | React.FunctionComponent;
  isOpen: boolean;
  title: string;
  destinationPath: string;
  currentPath: string;
  dispatch: Dispatch<AccountWalletCloseModalAction>;
}

export const AccountWalletModal = (props: Props) => {
  const {
    BaseComponent,
    modalTopChildren,
    isOpen,
    title,
    destinationPath,
    currentPath, // @TVG_MIGRATION we should try to remove this prop
    dispatch
  } = props;

  // @INFO On the main file it seems that we have some logic forcing mobile for FDR until tablet version exists
  // apps/frontend-fdm/src/shared/pages/index.jsx:359
  const device: Device = Device.MOBILE;
  const {
    //  device,
    product
  }: { device: Device; product: Product } = tvgConf();

  const navigate = useNavigate();

  const closeAccountWallet = () => {
    navigate(currentPath);
    dispatch(closeAccountWalletModal());

    if (getWrapperAppConfigCookie()) {
      removeWrapperAppConfigCookie();
    }

    if (isMobile()) {
      mediator.ios.dispatch({
        type: "USER_CLOSED_MODAL"
      });
    } else {
      mediator.base.dispatch({
        type: "CLOSED_AW_MODAL"
      });
    }
  };

  const modalprops = {
    title,
    titleType: "dark",
    isOpen,
    onClose: closeAccountWallet,
    isFullHeight: true,
    qaLabel: `modal-account-wallet`,
    // @ts-ignore
    useFakeInput: device === Device.TABLET && isMobile(product),
    useCloseLabel: true, // @TVG_MIGRATION We don't have this on tvg modal
    closeLabel: "Done", // @TVG_MIGRATION We don't have this on tvg modal
    hideFixedBanner: true, // @TVG_MIGRATION We don't have this on tvg modal
    forceModalTop: true, // @TVG_MIGRATION We don't have this on tvg modal
    modalTopChildren, // @TVG_MIGRATION Not sure but i think we don't have this on tvg modal
    animation: "bottom",
    isFdrDesk,
    isTransparent: isFdrDesk
  };

  return useMemo(
    () => (
      <BaseComponent {...modalprops}>
        {(transitionState: TransitionState) => (
          <AccountWalletWrapper
            transitionState={transitionState}
            path={destinationPath}
          />
        )}
      </BaseComponent>
    ),
    [isOpen, title, destinationPath, currentPath]
  );
};
export default AccountWalletModal;
