export enum MapImageEnum {
  PIN = "pin",
  ERROR = "error",
  LOGIN_ERROR = "loginError"
}

export enum ErrorDescriptionEnum {
  TEXT = "text",
  TIPS = "tips",
  STEPS = "steps"
}

export interface Props {
  qaLabel?: string;
  isGenericError?: boolean;
  title: string;
  imageType?: MapImageEnum;
  description?: ErrorDescription;
  errorsTitle?: string;
  errorsMessages?: ErrorMessage[];
  primaryButton?: {
    title: string;
    onClick: () => void;
  };
  secondaryButton?: {
    title: string;
    onClick: () => void;
  };
  onDismiss?: () => void;
}

export interface ErrorDescription {
  type?: ErrorDescriptionEnum;
  title?: string;
  messages?: string[];
}

export interface ErrorMessage {
  title?: string;
  message: string;
}
