import React from "react";
import { Paragraph, Header } from "@tvg/design-system/web";
import { useColorTokens, ColorTokens } from "@tvg/design-system";
import Information from "../SvgImages/Information";
import {
  ErrorListWrapper,
  MessageBlock,
  ContentMessage,
  IconContent
} from "./styled-components";
import { ErrorMessage } from "../types";

export interface Props {
  qaLabel?: string;
  title?: string;
  messages: ErrorMessage[];
}

export const ErrorPageMessages = ({
  qaLabel = "errorPage-messages",
  title,
  messages
}: Props) => {
  const { component, content } = useColorTokens() as ColorTokens;

  return (
    <ErrorListWrapper data-qa-label={qaLabel}>
      {title && (
        <Header
          tag="h3"
          qaLabel={`${qaLabel}-title`}
          fontFamily="bold"
          color={content.onDark}
        >
          {title}
        </Header>
      )}
      {messages.map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MessageBlock key={index} data-qa-label={`${qaLabel}-block`}>
          {message && (
            <>
              <IconContent>
                <Information />
              </IconContent>
              <ContentMessage>
                <Paragraph
                  color={component.notification.info.content}
                  qaLabel="message-title"
                  fontFamily="bold"
                >
                  {message.title as string}
                </Paragraph>
                <Paragraph
                  color={component.notification.info.content}
                  qaLabel="message-description"
                >
                  {message.message}
                </Paragraph>
              </ContentMessage>
            </>
          )}
        </MessageBlock>
      ))}
    </ErrorListWrapper>
  );
};

export default ErrorPageMessages;
