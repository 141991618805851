import React from "react";

const Information = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00006 12.4856V7.48557M8.00006 5.98557V4.48557M15.2504 8.48836C15.2504 12.4926 12.0043 15.7387 8.00007 15.7387C3.99583 15.7387 0.749756 12.4926 0.749756 8.48836C0.749756 4.48412 3.99583 1.23804 8.00007 1.23804C12.0043 1.23804 15.2504 4.48412 15.2504 8.48836Z"
      stroke="#005FC8"
      strokeWidth="1.5"
    />
  </svg>
);

export default Information;
